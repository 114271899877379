import fetch from 'common/fetch';

// particulars
export function getContractDetails(id) {
  return fetch({
    url: `/contractInfo/detail/${id}`,
    method: 'get'
  });
}

// 校验是否可以edit
export function getVality(contractNo) {
  return fetch({
    url: `/contractInfo/whetherEdit/${contractNo}`,
    method: 'get'
  });
}

//get请求
export function getBannerList(params) {
  return fetch({
    url: '/enterprise/list',
    method: 'post',
    data: params
  });
}
// restful风格接口参数拼接
export function deleteBanner(id, params) {
  return fetch({
    url: `接口地址/${id}`,
    method: 'delete',
    params
  });
}
//新增合同
export function addContract(params) {
  return fetch({
    url: '/contractInfo',
    method: 'post',
    data: params
  });
}

//edit合同
export function editContract(params) {
  return fetch({
    url: '/contractInfo/edit',
    method: 'post',
    data: params
  });
}

//绑定用户
export function bundleContractUser(params) {
  return fetch({
    url: '/system/user/bindContract',
    method: 'post',
    data: params
  });
}

//  审核合同
export function auditContract(params) {
  return fetch({
    url: '/contractInfo/approve',
    method: 'post',
    data : params
  })
}

//  审核合同
export function unBindContract(params) {
  return fetch({
    url: '/system/user/unBindContract',
    method: 'post',
    data : params
  })
}