<template>
  <div class="panel demoWrapper">
    <div class="tabtitle">
      <div class="title-left">
        <span style="font-size:16px">Enterprise Info</span>
      </div>
    </div>
    <el-form
      label-width="120px"
      :data="enterpriseInfo"
      class="search-form"
      style="height:120px;display:flex;padding-left:16px;padding-top:40px"
    >
      <el-form-item label="Contract Type:">
        <el-select
          v-model.trim="enterpriseInfo.contractType"
          :disabled="isShow"
          placeholder="Please select"
        >
          <el-option
            v-for="item in ContractTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Sales ERP:">
        <el-input
          v-model.trim="enterpriseInfo.saler"
          :disabled="isShow"
          placeholder=""
          prefix-icon="el-icon-search"
        />
      </el-form-item>
      <el-form-item label="Enterprise ID:">
        <el-input
          v-model.trim="enterpriseInfo.enterpriseName"
          :disabled="isShow"
          placeholder=""
          prefix-icon="el-icon-search"
        />
        <el-button :disabled="isShow" size="mini" @click="queryEnterprise">
          query
        </el-button>
      </el-form-item>
    </el-form>
    <!-- 查询Enterprise的弹框 -->
    <el-dialog title="Selection" :visible.sync="queryEnterVisible" width="50%">
      <hr>
      <el-input v-model.trim="queryEqName" />
      <el-button @click="queryEq">query</el-button>
      <el-table :data="dialogData" style="width: 100%" :header-cell-style="{ background: '#e8f1fe' }">
        <el-table-column prop="id" label="Enterprise Number" width="180" />
        <el-table-column
          prop="enterpriseName"
          label="Enterprise ID"
          width="180"
        />
        <el-table-column prop="address" label="Saler Erp" />
        <el-table-column prop="address" label="Operate">
          <template slot-scope="props">
            <el-button @click="selectName(props.row)">Selected</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="">
        <el-pagination
          :current-page="dialogPage1"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="dialogSize1"
          layout="total, sizes, prev, pager, next, jumper"
          :total="dialogtotal"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </el-dialog>
    <div class="tabtitle">
      <div class="title-left">
        <span style="font-size:16px">Contract Info</span>
      </div>
      <el-tabs type="border-card">
        <el-tab-pane label="Basic Info">
          <el-form
            label-width="120px"
            :data="basicInfo"
            class="search-form"
            style="padding-left:16px"
          >
            <el-row width="100%">
              <el-col :span="12">
                <el-form-item label="Contract No:">
                  <span>{{ this.basicInfo.contractNo }}</span>
                </el-form-item>
                <el-form-item label="Contract Begin Time:">
                  <el-date-picker
                    v-model.trim="contractStarttime"
                    :disabled="isShow"
                    align="right"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                    format="dd-MM-yyyy"
                    :picker-options="pickerOptions"
                    @change="startTime"
                  />
                </el-form-item>
                <el-form-item label="Enterprise Title">
                  <el-input
                    v-model.trim="enterpriseTitle"
                    :disabled="isShow"
                    max="100"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="OU:">
                  <!-- 需要确认字段名-------------- -->
                  <el-select
                    v-model.trim="ouInfo"
                    :disabled="isShow"
                    placeholder="Please select"
                  >
                    <el-option
                      v-for="item in OUTypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="Contract End Time:">
                  <el-date-picker
                    v-model.trim="contractEndtime"
                    :disabled="isShow"
                    align="right"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                    format="dd-MM-yyyy"
                    :picker-options="pickerOptions"
                    @change="endTime"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

          <el-table
            :data="tableData"
            style="width: 100%"
            :header-cell-style="{ background: '#eef1f6', color: 'black' }"
          >
            <el-table-column prop="Method" label="Payment Method">
              <template slot-scope="props">
                <el-checkbox-group
                  v-model.trim="props.row.Method"
                  :disabled="isShow"
                >
                  <el-checkbox label="1" name="1">Business Credit</el-checkbox>
                  <el-checkbox label="2" name="2">Bank Transfer</el-checkbox>
                  <el-checkbox label="3" name="3">Virtual Payment</el-checkbox>
                  <el-checkbox label="4" name="4">Credit Card</el-checkbox>
                  <el-checkbox label="5" name="5">
                    Credit Card Installment
                  </el-checkbox>
                  <el-checkbox label="6" name="6">Dolfin</el-checkbox>
                  <el-checkbox label="7" name="7">LinePay</el-checkbox>
                </el-checkbox-group>
              </template>
            </el-table-column>
            <el-table-column
              prop="isEnterpriseCredit"
              label="Support Business Credit"
            >
              <template slot-scope="props">
                <el-radio
                  v-model.trim="props.row.isEnterpriseCredit"
                  :disabled="isShow"
                  label="1"
                >
                  Yes
                </el-radio>
                <el-radio
                  v-model.trim="props.row.isEnterpriseCredit"
                  :disabled="isShow"
                  label="0"
                >
                  No
                </el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="isCod" label="Support COD(Cash On Delivery)">
              <template slot-scope="props">
                <el-radio
                  v-model.trim="props.row.isCod"
                  :disabled="isShow"
                  label="1"
                >
                  Yes
                </el-radio>
                <el-radio
                  v-model.trim="props.row.isCod"
                  :disabled="isShow"
                  label="0"
                >
                  No
                </el-radio>
              </template>
            </el-table-column>  
            <!--发票信息 0不支持  1支持-->
            <el-table-column prop="isInvoice" label="Invoice">
              <template slot-scope="props">
                <el-radio
                  v-model.trim="props.row.isInvoice"
                  :disabled="isShow"
                  label="1"
                >
                  Yes
                </el-radio>
                <el-radio
                  v-model.trim="props.row.isInvoice"
                  :disabled="isShow"
                  label="0"
                >
                  No
                </el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="invoiceType" label="Invoice Type">
              <template slot-scope="props">
                <el-radio
                  v-model.trim="props.row.invoiceType"
                  :disabled="isShow"
                  label="1"
                >
                  Common Invoice
                </el-radio>
                <el-radio
                  v-model.trim="props.row.invoiceType"
                  :disabled="isShow"
                  label="2"
                >
                  VAT Invoice
                </el-radio>
                <el-radio
                  v-model.trim="props.row.invoiceType"
                  :disabled="isShow"
                  label="3"
                >
                  Electronic Invoice
                </el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="invoiceMode" label="Invoice Mode">
              <template slot-scope="props">
                <el-radio
                  v-model.trim="props.row.invoiceMode"
                  :disabled="isShow"
                  label="1"
                >
                  Goods-Invoice Separation
                </el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="priceType" label="PriceMode">
              <template slot-scope="props">
                <el-checkbox-group
                  v-model.trim="props.row.priceType"
                  :disabled="isShow"
                >
                  <el-checkbox label="0" name="0">
                    Promotion Price
                  </el-checkbox>
                  <el-checkbox label="1" name="1">Agreement Price</el-checkbox>
                  <el-checkbox label="2" name="2">
                    Lower Price Priority
                  </el-checkbox>
                </el-checkbox-group>
              </template>
            </el-table-column>
            <el-table-column prop="poolType" label="Ware Pool Type">
              <template slot-scope="props">
                <el-radio
                  v-model.trim="props.row.poolType"
                  :disabled="isShow"
                  label="0"
                >
                  Standard Ware-pool
                </el-radio>

                <!-- <el-checkbox-group :disabled="isShow" v-model.trim="props.row.poolType">
                    <el-checkbox label="1" name="1">Standard Ware-pool</el-checkbox>
                  </el-checkbox-group> -->
              </template>
            </el-table-column>
            <el-table-column prop="takeDays" label="Operate">
              <template slot-scope="props">
                <el-input
                  v-model.trim="props.row.takeDays"
                  type="Number"
                  :disabled="isShow"
                  min="1"
                  max="10"
                />
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <div class="btn-foot">
          <el-button
            :disabled="isShow"
            type="primary"
            size="small"
            @click="editContractSub"
          >
            submit
          </el-button>
        </div>
      </el-tabs>
      <div class="tabtitle">
        <div class="title-left">
          <span style="font-size:16px">Contract Info</span>
        </div>
      </div>
      <div style="text-align:center;padding-top:50px">
        <span>Validity Type： </span>
        <el-select
          v-model.trim="ValidityType"
          placeholder="Please select"
          @change="valiType"
        >
          <el-option
            v-for="item in ValidityTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <div class="btn-foot">
          <el-button type="primary" size="small" @click="modififyValidit">
            modifify Validity
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getVality,
  editContract,
  getContractDetails,
  getBannerList
} from '@/api/contract/contract.js';

export default {
  // 引入局部刷新
  inject: ['reload'],
  props: {
    id: String
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          }
        ]
      },
      querData: {},
      dataForm: {},
      dialogPage1: 1,
      dialogSize1: 10,
      contractStarttime: '',
      contractEndtime: '',
      enterpriseInfo: {
        contractType: '',
        saler: '',
        enterpriseName: '',
        enterpriseId: ''
      },
      basicInfo: {
        contractNo: ''
      },
      dialogData: [],
      ContractTypeList: [
        {
          label: 'Vsp',
          value: '0'
        }
      ], // ContractTypeList下拉框
      SalesERP: '', // 必填项：关联人资系统校验
      EnterpriseVal: '',
      queryEnterVisible: false, // 获取queryEnterprise，弹框显示、隐藏
      queryEqName: '', // 弹框里 搜索项
      enterOU: '',
      ContractNo: '',
      OUTypeList: [
        {
          label: '1',
          value: 'PT.Retail'
        }
      ],

      tableData: [
        {
          Method: '',
          BusinessCredit: '',
          SupportCOD: '',
          InvoiceType: '',
          PriceMode: '',
          PoolType: '',
          Days: ''
        }
      ],

      isShow: true,
      ValidityType: 'Valid', //  1:Valid 2:InValid      修改合同需要先设置validity的状态从valid改成Invalid，之后进行修改操作，再改成Valid
      ValidityTypeList: [
        {
          lable: 'Valid',
          value: 'Valid'
        },
        {
          lable: 'InValid',
          value: 'InValid'
        }
      ],
      params: {
        page: 1,
        size: 10
      },
      //============================
      // 获取URL信息
      outeObject: this.$route.query,
      // 重置密码时校验内容
      rulesPass: {
        pin: [
          {
            required: true,
            message: 'please nter Enterprise User',
            trigger: 'blur'
          }
        ],
        pwd: [
          {
            required: true,
            message: 'please nter New Password',
            trigger: 'blur'
          },
          {
            min: 6,
            max: 16,
            message: 'Between 6 to 16 characters',
            trigger: 'blur'
          },
          {
            pattern: /^(?![^a-zA-Z]+$)(?!\D+$)/,
            message: 'must contain number & letter',
            trigger: 'blur'
          }
        ]
      }
    };
  },
  watch: {},
  created() {
    this.Info();
  },
  methods: {
    // 从父组件刚跳转过来
    Info() {
      //console.log(this.id, '跳转过来的ID');
      getContractDetails(this.id).then(res => {
        //console.log(res, '查询合同particulars');
        // 企业信息
        this.enterpriseInfo.contractType = res.data.contractType + '';
        this.enterpriseInfo.saler = res.data.saler;
        this.enterpriseInfo.enterpriseName = res.data.enterpriseName;
        this.enterpriseInfo.enterpriseId = res.data.enterpriseId;
        this.oldEnterInfo = this.enterpriseInfo;
        //console.log(this.enterpriseInfo, 'this.enterpriseInfo---1');
        // 基本信息
        this.basicInfo.contractNo = res.data.contractNo;
        this.basicInfo.contractStarttime =
          res.data.contractNo.contractStarttime;
        this.basicInfo.contractEndtime = res.data.contractEndtime;
        this.basicInfo.isEnterpriseCredit = res.data.isEnterpriseCredit + '';
        this.basicInfo.isCod = res.data.isCod + '';
        this.basicInfo.isInvoice = res.data.isInvoice + '';
        this.basicInfo.invoiceType = res.data.invoiceType + '';
        this.basicInfo.invoiceMode = res.data.invoiceMode + '';
        this.basicInfo.priceType = res.data.priceType + '';
        this.basicInfo.poolType = res.data.poolType + '';
        this.basicInfo.takeDays = res.data.takeDays + '';
        this.oldBasicInfo = this.basicInfo;
        //console.log(this.basicInfo, 'this.basicInfo-----2');
        //  时间回显
        this.contractStarttime = res.data.contractStarttime;
        this.contractEndtime = res.data.contractEndtime;

        // 单选复选数据
        let obj = {};
        obj.isEnterpriseCredit = res.data.isEnterpriseCredit + '';
        obj.isCod = res.data.isCod + '';
        obj.isInvoice = res.data.isInvoice + '';
        obj.invoiceType = res.data.invoiceType + '';
        obj.invoiceMode = res.data.invoiceMode + '';
        obj.priceType = res.data.priceType + '';
        obj.poolType = res.data.poolType + '';
        obj.takeDays = res.data.takeDays + '';
        this.tableData.splice(0, 1, obj);
        this.oldTableData = this.tableData;
        //console.log(this.tableData, 'this.tableData-----3');

        //==============================
        // this.oldArray = res.data
        // this.dataForm = res.data
        // this.dataForm.contractType = this.dataForm.contractType+''
        // let obj = {}
        // this.contractStarttime = this.dataForm.contractEndtime
        // this.contractEndtime = this.dataForm.contractEndtime
        // obj.isCod = this.dataForm.isCod+''
        // obj.invoiceMode = this.dataForm.invoiceMode+''
        // obj.invoiceType = this.dataForm.invoiceType+''
        // obj.isEnterpriseCredit = this.dataForm.isEnterpriseCredit+''
        // // obj.priceType.push(this.dataForm.priceType)
        // obj.priceType = []
        // obj.poolType=this.dataForm.poolType+''
        // obj.priceType.push(this.dataForm.priceType+'')
        // obj.takeDays = this.dataForm.takeDays
        // //console.log(this.dataForm,'909999');
        // this.tableData.splice(0,1,obj)
        // //console.log(this.tableData,'this.tableData');
        // this.oldArr = this.tableData
        // let arr = []
        // arr = arr.push(obj)
        // this.$nextTick(()=>{
        //   this.tableData = arr
        // })
      });
    },
    // 获取queryEnterprise，弹框显示、隐藏
    queryEnterprise() {
      this.queryEnterVisible = true;
      getBannerList({enterpriseInfoDTO: this.params}).then(res => {
        //console.log(res, 'res');
        this.dialogtotal = res.data.total;
        this.dialogPage1 = res.data.page;
        this.dialogSize1 = res.data.size;
        this.dialogData = res.data.rows;
        //console.log(this.dialogData, 'dialog里的数据');
      });
    },
    // queryEq 弹框内查询EQ的query按钮
    queryEq() {
      // 传值查询，生成列表
      this.params.enterpriseName = this.queryEqName;
      getBannerList({enterpriseInfoDTO: this.params}).then(res => {
        //console.log(res, 'res');
        this.dialogtotal = res.data.total;
        this.dialogPage1 = res.data.page;
        this.dialogSize1 = res.data.size;
        this.dialogData = res.data.rows;
        //console.log(this.dialogData, 'dialog里的数据');
      });
    },
    //选中的用户，将名字回显到页面输入框
    selectName(row) {
      //console.log(row);
      this.enterpriseInfo.enterpriseId = row.id;
      //   this.enterpriseInfo.enterpriseName = row.enterpriseName
      //  赋值完关闭dialog
      this.queryEnterVisible = false;
    },

    // 时间组件，值发生变化
    startTime(val) {
      //console.log(val, '开始时间');
      this.dataForm.contractStarttime = val;
    },
    endTime(val) {
      //console.log(val, '结束时间');
      this.dataForm.contractEndtime = val;
    },

    // 修改合同需要先设置validity的状态从valid改成Invalid，之后进行修改操作，再改成Valid
    // 改合同状态从valid改成Invalid时校验是否有进行中的预订单，如果有则不允许修改
    editContractSub() {
      //console.log(this.enterpriseInfo, '提交表单数据');
      //console.log(this.tableData, '单选的数据');
      //console.log(this.basicInfo, '基本信息');
      if (
        this.enterpriseInfo !== this.oldEnterInfo ||
        this.basicInfo !== this.oldBasicInfo
      ) {
        this.dataForm.editState = 1;
      }
      if (this.tableData !== this.oldTableData) {
        this.dataForm.editState = 2;
      }
      if (
        this.tableData !== this.oldTableData &&
        (this.enterpriseInfo !== this.oldEnterInfo ||
          this.basicInfo !== this.oldBasicInfo)
      ) {
        this.dataForm.editState = 3;
      }
      var c = Object.assign(
        this.enterpriseInfo,
        this.tableData[0],
        this.basicInfo,
        this.dataForm
      );
      //console.log(c, '=============---------');
      this.dataForm = c;
      this.dataForm.editState = 1;
      this.dataForm.priceType = Number(this.tableData[0].priceType);
      this.dataForm.poolType = Number(this.tableData[0].poolType);
      this.dataForm.invoiceType = Number(this.tableData[0].invoiceType);
      this.dataForm.invoiceMode = Number(this.tableData[0].invoiceMode);
      this.dataForm.isCod = Number(this.tableData[0].isCod);
      this.dataForm.isInvoice = Number(this.tableData[0].isInvoice);
      this.dataForm.isEnterpriseCredit = Number(
        this.tableData[0].isEnterpriseCredit
      );
      this.dataForm.takeDays = Number(this.tableData[0].takeDays);
      this.dataForm.contractType = Number(this.dataForm.contractType);
      this.dataForm.dataVersion = 1;
      this.dataForm.creator = '创建人';
      this.dataForm.payPercent = 100;
      //   this.dataForm.editState = 1

      //   if (this.oldArr !== this.tableData) {
      //     this.dataForm.editState = 2
      //   }
      //   if (this.oldArray.contractType !== this.dataForm.contractType ||
      //   this.oldArray.enterpriseName !== this.dataForm.enterpriseName ||
      //   this.oldArray.saler !== this.dataForm.saler ||
      //   this.oldArray.contractNo !== this.dataForm.contractNo ||
      //   this.oldArray.contractStarttime !== this.dataForm.contractStarttime ||
      //   this.oldArray.enterpriseTitle !== this.dataForm.enterpriseTitle ||
      //   this.oldArray.contractEndtime !== this.dataForm.contractEndtime ) {
      //     this.dataForm.editState = 1
      //   }
      editContract(this.dataForm).then(res => {
        //console.log(res, '新增接口返回');
        if (res.code == 200) {
          this.$message('Operation succeeded');
          //   this.$router.push({name:'contract list'})
        }
      });
    },

    valiType(val) {
      //console.log(val, '----------');
      this.ValidityType = val;
    },
    modififyValidit() {
      if (this.ValidityType == 'InValid') {
        let contractNo = this.dataForm.contractNo;
        getVality(contractNo).then(res => {
          //console.log(res, '校验');
          this.isShow = res.data;
        });
      }
      //  修改完看valiType取值，为1不可修改表单内容，为2可修改
    }
  }
};
</script>

<style lang="less" scoped></style>
